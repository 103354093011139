<template>
  <div>
    <UserQueryCard search="" />
  </div>
</template>
<script>
import UserQueryCard from "./components/UserQueryCard";
export default {
  components: { UserQueryCard },
  data() {
    return {
      search: "",
    };
  },
};
</script>
